import React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import CanvasCollection from "../components/CanvasCollection";
import PageFooter from "../components/Layout/PageFooter/";
import SEO from "../components/SEO";

const CanvasTemplate = ({ data, location, pageContext }) => {
  const { title, body, collections, canvasRatio } = data.contentfulPage;
  const { slug } = pageContext;
  const postNode = data.contentfulPage;

  return (
    <Layout showLogo location={location}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />

      <CanvasCollection canvasRatio={canvasRatio} collections={collections} />
      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      metaDescription {
        internal {
          content
        }
      }
      body {
        json
      }
      canvasRatio
      collections {
        poster {
          file {
            url
          }
        }
        previewPicture {
          file {
            url
            contentType
          }
        }
        slug
        title
        subtitle
        theme
        positionX
        exactPositionX
        positionY
        exactPositionY
        width
        exactWidth
        elements {
          file {
            url
            # check file type
            contentType
          }
          fluid {
            src
          }
        }
      }
    }
  }
`;

export default CanvasTemplate;
